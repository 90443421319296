const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Sobre Mim",
    url: "/about/",
  },
  {
    label: "RSS Feed",
    url: "/rss.xml",
  }
]

export default links
