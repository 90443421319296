//const handle = "johndoe"

const links = [
  {
    label: "Github",
    url: `https://github.com/BeatrizCarvalho`,
  },
  {
    label: "Twitter",
    url: `https://twitter.com/OpenSourceresss`,
  },
  /*  {
    label: "Youtube",
    url: `https://www.youtube.com/${handle}`,
  },*/
  {
    label: "Instagram",
    url: `https://www.instagram.com/open.sourceress`,
  },
]

export default links
